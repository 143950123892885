<template>
  <v-dialog
    :value="show"
    :max-width="$dialog.medium"
  >
    <v-card class="pa-5">
      <v-sheet :max-width="$dialog.content" class="mx-auto">
        <v-card-title primary-title>
          <h5 class="text-h5 font-weight-bold">
            Project saved!
          </h5>

          <v-btn
            @click="$emit('close')"
            absolute
            icon
            right
            top
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3">
          <p class="body-1">
            Your download will begin shortly...
          </p>

          <v-alert type="warning" class="body-1" border="left" text>
            Heads up! Your project will be deleted in 3 days
          </v-alert>

          <p class="body-1">
            Create a free account now to remove this time limit and enable other useful features, such as multiple pages and projects.
          </p>

          <div class="mt-7">
            <v-btn
              @click="
                $store.commit('user/setShowDialog', true)
                $store.commit('user/setActionType', 'signup')
                $emit('close')
              "
              class="gradient"
              color="accent"
            >
              Create Free Account
            </v-btn>
          </div>
        </v-card-text>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean
  }
}
</script>