<template>
  <v-dialog
    v-model="dialog"
    :max-width="$dialog.content"
  >
    <v-card>
      <v-card-title>
        <span class="font-weight-bold text-h5">
          {{ data.id ? 'Update' : 'Add New' }} Project Template
        </span>

        <close-btn @click="closeDialog()" depressed />
      </v-card-title>
      <v-card-text class="pt-3">
        <v-form @submit.prevent="validateForm()" ref="form">
          <v-text-field
            v-model="data.name"
            :rules="[rules.required]"
            label="Name"
            outlined
          ></v-text-field>

          <v-select
            v-model="data.category"
            :rules="[rules.required]"
            :items="categories"
            label="Category"
            item-text="name"
            item-value="id"
            outlined
          ></v-select>

          <v-btn 
            @click="validateForm()" 
            :loading="status.creating"
            color="accent gradient" 
            block
          >
            {{ data.id ? 'Update' : 'Add' }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import rules from '@/rules'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      dialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: (state) => state.projects.data,
      status: (state) => state.projects.status,
      categories: (state) => state.projectTemplates.categories,
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('projects', [
      'create'
    ]),
    
    ...mapActions('allProjects', [
      'updateTemplate',
      'saveProjectAsTemplate'
    ]),
    
    ...mapActions('projectTemplates', [
      'getCategories'
    ]),

    closeDialog() {
      this.dialog = false
      
      this.$store.commit('projects/setData', { folder: 'root' })
      this.$store.commit('projects/updateStatus', { projectToTemplate: false })
      this.$refs.form.resetValidation()
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        let task

        if (this.status.projectToTemplate) task = this.saveProjectAsTemplate()
        else if (this.data.id) task = Promise.resolve(this.updateTemplate(this.data))
        else task = Promise.resolve(this.create(true))
        
        task.then(() => {
          this.$store.commit('projects/setData', { folder: 'root' })
          this.closeDialog()
        })
      }
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.categories.length) this.getCategories()
  }
}
</script>