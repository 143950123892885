<template>
  <v-card outlined>
    <div class="template-menu" v-if="pages.length">
      <div
        v-for="page in orderBy(pages, 'order', 'asc')"
        :key="page.id"
      >
        <a 
          @click="setActivePage(page)"
          :class="{ 'active' : active == page }"
        >{{ page.name }}</a>
      </div>
    </div>

    <page-section
      v-if="headerSection"
      :section="headerSection"
      nonEditable
    />

    <template v-if="sections && sections.length">
      <page-section
        v-for="section in orderBy(sections, 'order', 'asc')"
        :key="section.id"
        :section="section"
        nonEditable
      />
    </template>

    <page-section
      v-if="footerSection"
      :section="footerSection"
      nonEditable
    />
  </v-card>
</template>

<script>
import { orderBy } from 'lodash'
import PageSection from '@/components/PageSection'

export default {
  props: {
    project: Object
  },
  
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return this.defaultData()
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    PageSection
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'project': {
      handler() {
        Object.assign(this.$data, this.defaultData())
        this.getPageSections()
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    defaultData: () => {
      return {
        orderBy,
        pages: [],
        sections: [],
        active: null,
        headerSection: null,
        footerSection: null,
        gettingSections: false,
      }
    },

    // GET PAGE SECTIONS
    getPageSections() {
      this.pages = []

      this.project.ref
      .collection('pages')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let page = doc.data()
            page.id = doc.id
            page.ref = doc.ref
            this.pages.push(page)
          })

          this.setActivePage(orderBy(this.pages, 'order', 'asc')[0])
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    },

    // SET ACTIVE PAGE
    setActivePage(page) {
      this.active = page
      this.getSections(page)
    },

    // GET SECTIONS
    getSections(page) {
      this.sections = []
      this.headerSection = null
      this.footerSection = null

      this.project.ref
      .collection('globals')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          let docs = []
          
          snapshot.forEach(doc => {
            let data = doc.data()
            data.id = doc.id
            data.ref = doc.ref
            docs.push(data)
          })

          this.headerSection = docs.find(d => d.type == 'header')
          this.footerSection = docs.find(d => d.type == 'footer')
        }
      })
      .catch(error => {
        console.log(error.message)
      })

      page.ref
      .collection('sections')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let section = doc.data()
            section.id = doc.id
            section.ref = doc.ref
            this.sections.push(section)
          })
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  },
}
</script>

<style lang="scss">

.template-menu {
  position: absolute;
  left: -170px;

  a {
    font-size: 0.8rem;
    text-decoration: none;

    &.active {
      color: var(--v-primary-base) !important;
    }
  }
}

</style>