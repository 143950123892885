<template>
  <div>
    <h5 :class="$classes.drawerTitle">Attachments</h5>

    <v-form ref="attachmentForm">
      <v-file-input
        v-show="!status.uploading"
        placeholder="Select single or multiple files"
        v-model="data.file"
        @change="filesSelected"
        @click:clear="$store.commit('attachments/clearProgress')"
        outlined
        multiple
        dense
      ></v-file-input>
    </v-form>

    <!-- TO UPLOAD -->
    <div class="mt-7">
      <template v-if="progress.length">
        <v-fade-transition group>
          <div 
            v-for="(file, i) in progress"
            :key="i"
            class="mb-3"
          >
            <div class="d-flex mb-1">
              <span class="caption ">{{ file.name }}</span>
              <v-spacer></v-spacer>
              <span class="caption">{{ file.size | fileSize }}</span> 
              <v-btn
                v-if="!status.uploading"
                @click="$store.commit('attachments/removeProgress', file.name)"
                class="ml-1"
                x-small
                icon
              >
                <v-icon x-small>mdi-delete-outline</v-icon>
              </v-btn>
            </div>
            <v-progress-linear :value="file.progress" class="mb-2" rounded></v-progress-linear>
          </div>
        </v-fade-transition>

        <div class="mt-5">
          <v-btn
            @click="uploadAttachments()"
            :loading="status.uploading"
            color="accent dark--text"
            small
          >Upload</v-btn>
        </div>
      </template>
    </div>

    <!-- ATTACHMENTS -->
    <preloader v-if="status.getting" />

    <div v-if="files.length && !status.getting" class="mt-7">
      <div class="mb-3">Attachments ({{ files.length }})</div>

      <v-fade-transition group>
        <v-card 
          v-for="file in confFiles" 
          :key="file.id"
          class="mb-2"
          outlined
        >
          <v-card-text class="pa-2">
            <div class="d-flex">
              <span class="caption text-truncate d-inline-block" style="max-width: 180px">
                {{ file.originalName }} 
              </span> 
              <span class="grey--text ml-1 caption">({{ file.size | fileSize }})</span>
              <v-spacer></v-spacer>
              <div class="d-flex gap-7">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="$downloadFile('attachments', file.name)"
                      icon
                      x-small
                      v-on="on"
                    >
                      <v-icon small>mdi-arrow-down-bold-circle</v-icon>
                    </v-btn>
                  </template>
                  <span class="caption">Dowload</span>
                </v-tooltip>
                
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="deleteFile(file)"
                      icon
                      x-small
                      v-on="on"         
                    >
                      <v-icon small color="red">mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span class="caption">Delete</span>
                </v-tooltip>
              </div>
            </div>
          </v-card-text>
        </v-card>      
      </v-fade-transition>
    </div>

    <v-alert :value="!status.getting && !files.length" class="mt-7" dense text>
      No attachment found
    </v-alert>

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :show="deleteDialog"
      :message="deleteMessage"
      :deleting="status.deleting"
      @cancel="closeDeleteDialog()"
      @confirmed="deleteConfirmed()"
    />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import _orderBy from 'lodash/orderBy'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      deleteDialog: false,
      deleteMessage: null,
      toDelete: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.attachments.data,
      progress: state => state.attachments.progress,
      status: state => state.attachments.status,
      section: state => state.sections.selected,
      files: state => state.attachments.files,
    }),

    confFiles: function () {
      return _orderBy(this.files, 'created', 'desc')
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'section': function (val) {
      if (val) {
        this.getAttachments(this.section)
      }
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('attachments', [
      'uploadAttachments',
      'getAttachments',
      'deleteAttachment',
    ]),

    filesSelected(e) {
      if (e.length) {
        e.forEach(file => {
          this.$store.commit('attachments/setProgress', {
            name: file.name,
            progress: 0,
            size: file.size
          })
        })
      }
    },

    deleteFile(file) {
      this.toDelete = file
      this.deleteMessage = `Delete attachment "${file.originalName}"?`
      this.deleteDialog = true
    },

    closeDeleteDialog() {
      this.toDelete = null
      this.deleteMessage = null
      this.deleteDialog = false
    },

    deleteConfirmed() {
      Promise.all([
        this.deleteAttachment(this.toDelete)
      ])
      .then(() => {
        this.closeDeleteDialog()
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (this.section) {
      this.getAttachments(this.section)
    }
  }
}
</script>