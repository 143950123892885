<template>
  <v-dialog
    v-model="dialog"
    no-click-animation
    fullscreen 
    persistent
  >
    <v-card class="fill-height" :style="{ overflow: 'hidden' }">
      <close-btn @click="closeDialog()" depressed class="mt-3" />

      <v-card-title>
        <h5 class="font-weight-bold text-h5">Page Templates</h5>
      </v-card-title>
      
      <v-divider></v-divider>
      

      <div class="d-flex align-start" :style="{ height: 'calc(100% - 58px)' }">
        <v-card class="fill-height" width="300" outlined tile>
          <v-card-title class="font-weight-bold body-lg">
            Categories
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <Categories v-bind:categories.sync="categs" nonEditable />      
          </v-card-text>
          <v-divider></v-divider>
          <v-card-title class="font-weight-bold body-lg">
            Templates
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <PageTemplatesList 
              :categories="categs" 
              @selected="templateSelected" 
              showVisiblesOnly
            />
          </v-card-text>
        </v-card>

        <v-card class="mx-auto py-5 fill-height flex-grow-1" :style="{ overflowY: 'scroll' }">

          <v-sheet max-width="750" class="mx-auto">
            <v-alert type="info" border="left" text :value="!selected">
              Please select a template.
            </v-alert>

            <div v-if="selected" class="d-flex mb-3">
              <div v-if="status.creatingFromTemplate">Please wait...</div>
              <v-spacer></v-spacer>
              <v-btn 
                @click="createPageFromTemplate()"
                :loading="status.creatingFromTemplate"
                color="accent dark--text" 
                small
              >Use Template</v-btn>
            </div>

            <template-preview
              v-if="selected"
              :template="selected"
              previewOnly
            ></template-preview>
          </v-sheet>
        </v-card>
      </div>
    </v-card> 

    <!-- DIALOG -->
    <confirm-delete
      :show="confirmDialog"
      message="You already have existing body sections for this page. The current layout will be overridden and all notes, attachments, and content copy will be deleted together with the section."
      @cancel="confirmDialog = false"
      @confirmed="createConfirmed()"
    />
  </v-dialog>
</template>

<script>
import Categories from './Categories'
import TemplatePreview from './TemplatePreview'
import PageTemplatesList from './PageTemplatesList'
import { mapState, mapActions } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      dialog: false,
      selected: null,
      confirmDialog: false,
      categs: [],
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: (state) => state.page.status,
      sections: (state) => state.sections.sections,
    })
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Categories,
    TemplatePreview,
    PageTemplatesList,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('page', [
      'createFromTemplate'
    ]),
    
    ...mapActions('sections', [
      'deletePageSections'
    ]),

    closeDialog() {
      this.dialog = false
    },

    templateSelected(template) {
      this.selected = template
    },

    createConfirmed() {
      this.confirmDialog = false

      Promise.all([this.deletePageSections()])
      .then(() => {
        Promise.all([this.createFromTemplate(this.selected)])
        .then(() => {
          this.closeDialog()
        })
      })
    },

    createPageFromTemplate() {
      if (this.sections.length) {
        this.confirmDialog = true
      }
      else {
        this.createConfirmed()
      }
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.$root.$on('showSelectPageTemplateDialog', () => {
      this.dialog = true
    })
  }
}
</script>