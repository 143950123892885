<template>
  <v-dialog
    v-model="dialog"
    no-click-animation
    fullscreen 
    persistent
  >
    <v-card class="fill-height" :style="{ overflow: 'hidden' }">
      <span :style="{ position: 'absolute', right: '70px', fontSize: '0.8rem', marginTop: '20px' }">Build from scratch</span>

      <close-btn @click="closeDialog()" depressed class="mt-3" />

      <v-card-title>
        <h5 class="font-weight-bold text-h5">Project Templates</h5>
      </v-card-title>
      
      <v-divider></v-divider>
      

      <div class="d-flex align-start" :style="{ height: 'calc(100% - 58px)' }">
        <v-card class="fill-height" width="300" outlined tile>
          <v-card-title class="font-weight-bold body-lg">
            Categories
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <Categories v-bind:categories.sync="categs" nonEditable />      
          </v-card-text>
          <v-divider></v-divider>
          <v-card-title class="font-weight-bold body-lg">
            Templates
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <ProjectTemplateList 
              :categories="categs" 
              @selected="templateSelected" 
              showVisiblesOnly
            />
          </v-card-text>
        </v-card>

        <v-card class="mx-auto py-5 fill-height flex-grow-1" :style="{ overflowY: 'scroll' }">

          <v-sheet max-width="750" class="mx-auto">
            <v-alert type="info" border="left" text :value="!selected">
              Please select a template.
            </v-alert>

            <div v-if="selected" class="d-flex mb-3">
              <div v-if="status.creatingFromTemplate">Please wait...</div>
              <v-spacer></v-spacer>
              <v-btn 
                @click="createProject()"
                :loading="status.creating"
                color="accent dark--text" 
                small
              >Use Template</v-btn>
            </div>

            <template-preview
              v-if="selected"
              :project="selected"
            ></template-preview>
          </v-sheet>
        </v-card>
      </div>
    </v-card> 

    <!-- DIALOG -->
    <confirm-delete
      :show="confirmDialog"
      message="You already have existing body sections for this page. The current layout will be overridden and all notes, attachments, and content copy will be deleted together with the section."
      @cancel="confirmDialog = false"
      @confirmed="createConfirmed()"
    />
  </v-dialog>
</template>

<script>
import Categories from './Categories'
import TemplatePreview from './TemplatePreview'
import ProjectTemplateList from './ProjectTemplateList'
import { mapState, mapActions } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      categs: [],
      dialog: false,
      selected: null,
      confirmDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: (state) => state.allProjects.status,
      templates: (state) => state.allProjects.templates,
    })
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Categories,
    TemplatePreview,
    ProjectTemplateList,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('allProjects', [
      'createFromTemplate'
    ]),

    templateSelected(template) {
      this.selected = template
    },
    
    closeDialog() {
      this.dialog = false
    },

    createProject() {
      Promise.resolve(this.createFromTemplate(this.selected))
      .then(() => {
        this.$emit('getDetails')
        this.closeDialog()
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.$root.$on('showSelectPageTemplateDialog', () => {
      this.dialog = true
    })
  }
}
</script>