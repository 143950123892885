<template>
  <div>
    <preloader v-if="getting" message="Getting body sections..." />
    
    <v-hover v-slot="{ hover }">
      <v-card
        v-if="!getting && !sections.length && !$store.state.preview"
        class="section-card dashed transition"
        :class="{ 'box-shadow' : hover }"
        style="margin-bottom: -2px;"
        @click="selectTemplate()" 
        outlined 
        tile 
      >
        <v-sheet min-height="200" class="d-flex justify-center align-center">
          <h6 class="primary--text body-1 subtle-text d-inline-flex align-center">
            <v-icon left>mdi-plus-circle-outline</v-icon>
            ADD A SECTION
          </h6>
        </v-sheet>
      </v-card>
    </v-hover>

    <template v-if="!getting && sections.length">
      <draggable v-model="compSections" :disabled="$store.state.preview">
        <page-section
          v-for="section in compSections"
          :key="section.id"
          :section="section"
          @select="selectTemplate()"
          @delete="deleteSection"
          @replace="replaceSection"
        />
      </draggable>
    </template>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import PageSection from '@/components/PageSection'
import draggable from 'vuedraggable'

export default {
  name: 'BodySections',

  props: {
    page: null,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      getting: true,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/

  components: {
    PageSection,
    draggable,
  },
 
  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      sections: state => state.sections.sections
    }),

    compSections: {
      get() {
        return this.sections
      },

      set(newVal) {
        this.$store.commit('sections/updateSections', newVal)
      }
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'page': function () {
      this.getting = true
      this.$store.commit('sections/emptyCodes')
      this.getPageSections()
    },

    'sections': function (sections) {
      this.updateSectionOrder()
      
      if (sections.length && this.$store.state.sections.task !== 'replace') {
        this.$store.commit('sections/reorderBodyShortcodeCss')
      }
    }
  },
  
  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('sections', [
      'getSections',
      'updateSectionOrder'
    ]),

    selectTemplate() {
      this.$emit('select', 'body')
    },

    getPageSections() {
      Promise.all([
        this.getSections(this.page)
      ])
      .then(() => {
        this.getting = false
      })
    },

    deleteSection(section) {
      this.$emit('delete', section)
    },

    replaceSection(section) {
      this.$emit('replace', section)
    },
  },

  mounted() {
    if (this.page) this.getPageSections()
  }
}
</script>