<template>
  <v-dialog
    v-model="dialog"
    :max-width="$dialog.content"
    transition="dialog-transition"
  >
    <v-card>
      <v-btn
        @click="dialog = false"
        absolute
        right
        icon
        top
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-title class="font-weight-bold text-h5">
        Save Page As Template
      </v-card-title>
      <v-card-text class="pt-3">
        <v-form ref="saveForm">
          <v-text-field
            v-model="data.name"
            :rules="[rules.required]"
            label="Template Name"
            outlined
          ></v-text-field>

          <v-autocomplete
            v-model="data.categories"
            label="Select Categories"
            :items="refined"
            item-text="name"
            item-value="id"
            deletable-chips
            small-chips
            outlined
            multiple
            chips
          ></v-autocomplete>

          <div class="d-flex gap-5">
            <v-btn 
              @click="confirmSave()"
              :loading="status.saving"
              color="accent gradient"
            >Save</v-btn>
            
            <v-btn 
              :disabled="status.saving"
              @click="dialog = false" 
              text
            >Cancel</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { orderBy } from 'lodash'
import rules from '@/rules'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: ['page', 'header', 'footer'],
  
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      dialog: false,
      data: {},
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      categories: (state) => state.pageTemplates.categories,
      status: (state) => state.pageTemplates.status,
    }),

    refined: function () {
      return orderBy(this.categories, 'name', 'asc')
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('pageTemplates', [
      'getCategories',
      'saveTemplate'
    ]),

    confirmSave() {
      if (this.$refs.saveForm.validate()) {
        Promise.all([
          this.saveTemplate({
            page: this.page,
            header: this.header,
            footer: this.footer,
            data: this.data
          })
        ])
        .then(() => {
          this.dialog = false
          this.data = {}
          this.$refs.saveForm.resetValidation()
        })
      }
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.categories.length) this.getCategories()
  }
}
</script>

