<template>
  <div>
    <h5 :class="$classes.drawerTitle">Notes</h5>
  
    <v-form ref="noteForm" @submit.prevent="validateForm()">
      <v-textarea
        v-model="data.message"
        :rules="[rules.required]"
        placeholder="Type your note/message"
        outlined
        count
      ></v-textarea>

      <div class="text-right">
        <v-btn
          @click="validateForm()"
          :loading="status.creating"
          color="accent dark--text"
          small
        >Submit</v-btn>
        
        <v-btn
          v-if="data.id"
          @click="cancelEdit()"
          :disabled="status.creating"
          class="ml-1"
          small
          text
        >Cancel</v-btn>
      </div>
    </v-form>

    <!-- NOTES SECTION -->
    <div class="mt-5">
      <preloader v-if="status.getting" message="Loading notes..." />

      <v-alert :value="!status.getting && !notes.length" dense text>
        No notes found
      </v-alert>

      <v-list v-if="!status.getting && notes.length">
        <v-fade-transition group>
          <v-list-item
            v-for="note in orderedNotes"
            :key="note.id"
            class="align-flex-start"
            :class="{ 'grey lighten-4' : data.id == note.id }"
          >
            <v-list-item-avatar style="align-self: flex-start !important" class="mt-4">
              <user-photo
                v-if="getUserData(note.user).userid"
                :id="getUserData(note.user).userid"
                :size="40"
                photoSize="thumb"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <div class="d-flex align-center">
                  {{ getUserData(note.user).fullName }} <span class="grey--text ml-1">{{ note.edited || note.created | fromNow }} <span v-if="note.edited" class="grey--text text--lighten-1"><v-icon x-small>mdi-circle-small</v-icon> edited</span></span>
                  <v-spacer></v-spacer>
                  <v-menu v-if="note.user == $store.state.user.user.userid" bottom left offset-y transition="slide-x-transition" nudge-bottom="12">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        class="subtle"
                        icon
                        v-on="on"
                        small
                      >
                        <v-icon small>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="$store.commit('notes/setData', note)" dense class="subtle">
                        <v-list-item-title class="primary--text"><v-icon small left>mdi-form-textbox</v-icon> Edit</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item @click="deleteUserNote(note)" class="subtle" dense>
                        <v-list-item-title>
                          <v-icon small left class="red--text">mdi-delete-outline</v-icon> <span class="red--text">Delete</span> 
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-list-item-title>
              <v-list-item-content class="grey--text text--darken-1" style="line-height: 1.4; white-space: pre-line;">
                <v-card 
                  v-html="$options.filters.linkify(note.message)" 
                  class="grey--text text--darken-2" 
                  style="line-height: 1.3;"
                  color="transparent" 
                  flat tile 
                ></v-card>
              </v-list-item-content>
            </v-list-item-content>
          </v-list-item>
        </v-fade-transition>
      </v-list>
    </div>

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :show="deleteDialog"
      :message="deleteMessage"
      :deleting="status.deleting"
      @cancel="closeDelete()"
      @confirmed="deleteConfirmed()"
    />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import rules from '@/rules'
import _orderBy from 'lodash/orderBy'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return  {
      rules,
      deleteDialog: false,
      toDelete: null,
      deleteMessage: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      notes: state => state.notes.notes,
      users: state => state.notes.users,
      data: state => state.notes.data,
      status: state => state.notes.status,
      section: state => state.sections.selected,
    }),

    orderedNotes: function () {
      return _orderBy(this.notes, 'created', 'desc')
    },
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'section': function (val) {
      if (val) {
        this.getNotes(this.section)
      }
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('notes', [
      'getNotes',
      'createNote',
      'deleteNote',
      'updateNote'
    ]),

    validateForm() {
      if (this.$refs.noteForm.validate()) {
        let task
        
        if (this.data.id) task = Promise.all([this.updateNote()])
        else task = Promise.all([this.createNote(this.section)])
        
        task.then(() => {
          this.$refs.noteForm.resetValidation()
        })
      }
    },

    getUserData(id) {
      return this.users.find(user => user.userid == id) || {}
    },

    deleteUserNote(note) {
      this.deleteMessage = "Delete note?"
      this.deleteDialog = true
      this.toDelete = note
    },

    closeDelete() {
      this.deleteDialog = false
      this.deleteMessage = null
      this.toDelete = null
    },

    deleteConfirmed() {
      Promise.all([
        this.deleteNote(this.toDelete)
      ])
      .then(() => {
        this.closeDelete()
      })
    },

    cancelEdit() {
      this.$store.commit('notes/cancelEdit')
      this.$store.commit('notes/setData', {})
      this.$refs.noteForm.resetValidation()
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (this.section) {
      this.getNotes(this.section)
    }
  }
}
</script>