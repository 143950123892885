<template>
  <v-sheet v-show="sections.length >= $store.getters['generalSettings/pageBanner'] && !$store.getters['user/isAdmin'] && !$store.getters['user/isEmployee']" max-width="950" class="mx-auto">
    <v-alert color="info" border="left" dismissible light text>
      Would you like us to build this website for you? 
      <a href="https://www.google.com/url?q=https://calendly.com/lightningsites-sales/builder&sa=D&source=editors&ust=1632191261082000&usg=AOvVaw1ZQRFGvylGZaDDf4hcmsUm" target="_blank" class="font-weight-bold">Click here</a> to schedule a call with us.
    </v-alert>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    project: {
      type: Object,
      required: true
    }
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      sections: [],
      dialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      pageSections: (state) => state.sections.sections
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'pageSections': function () {
      this.getProjectSections()
    },

    'project': function () {
      this.getProjectSections()
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    getProjectSections() {
      this.sections = []

      this.project.ref
      .collection('pages')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(page => {
            page.ref
            .collection('sections')
            .get()
            .then(sectionSnapshot => {
              if (sectionSnapshot.size) {
                sectionSnapshot.forEach(doc => {
                  if (!this.sections.find(section => section == doc.id))
                    this.sections.push(doc.id)
                })
              }
            })
          })
        }
      })

      this.project.ref
      .collection('globals')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
         snapshot.forEach(doc => {
            if (!this.sections.find(section => section == doc.id))
              this.sections.push(doc.id)
          }) 
        }
      })
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getProjectSections()
  }
}
</script>