<template>
  <v-card
    max-width="450"
    outlined
    class="pa-5"
  >
    <template v-if="!$store.getters['collaborators/hasPendingRequest']">
      <v-card-title class="justify-center">
        <v-icon left>mdi-hand-left</v-icon>
        <h6 class="font-weight-bold text-h6">Unauthorized</h6>
      </v-card-title>
      <v-card-text class="text-center">
        <p>You don't have permission to view/edit this project.</p>
        
        <v-btn
          v-if="user"
          @click="requestAccess(project)"
          :loading="status.requesting"
          color="accent" 
          class="gradient" 
          block
        >Request Access</v-btn>
      </v-card-text>
    </template>
    <template v-else>
      <v-card-text>
        <div class="text-center">Waiting for the project owner to accept access request.</div>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import firebase from 'firebase'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: ['project'],

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: (state) => state.collaborators.status,
    }),

    user: function () {
      return firebase.auth().currentUser
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('collaborators', [
      'requestAccess'
    ])
  }
}
</script>