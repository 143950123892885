<template>
  <div>
    <h5 :class="$classes.drawerTitle">Content Copy</h5>

    <v-alert type="error" transition="fade" :value="!!status.error" border="left" dense>
      {{ status.error }}
    </v-alert>

    <preloader v-if="status.getting" />
    
    <v-form v-if="!status.getting" ref="copyForm" @submit.prevent="validateForm()">
      <v-text-field
        v-model="data.url"
        :rules="[rules.url]"
        label="Copy URL"
        outlined
        dense
      >
        <template v-slot:append>
          <v-btn 
            v-clipboard:copy="data.url"
            v-clipboard:success="copied"
            icon
            x-small
          >
            <v-icon color="primary lighten-3">mdi-link-variant</v-icon>
          </v-btn>
        </template>
      </v-text-field>

      <v-textarea
        v-model="data.copy"
        label="Or paste copy directly here"
        height="500"
        outlined
      >
        <template v-slot:append>
          <v-btn 
            v-clipboard:copy="data.copy"
            v-clipboard:success="copied"
            icon
            x-small
          >
            <v-icon color="primary lighten-3">mdi-content-copy</v-icon>
          </v-btn>
        </template>
      </v-textarea>

      <div class="text-right">
        <v-btn
          @click="validateForm()"
          :loading="status.saving"
          color="accent dark--text"
          small
        >Submit</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.copy.data,
      status: state => state.copy.status,
      section: state => state.sections.selected
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'section': function (val) {
      if (val) this.getCopy(this.section)
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('copy', [
      'getCopy',
      'saveCopy'
    ]),

    copied() {
      this.$store.dispatch('showSuccess', 'Copied to clipboard.')
    },

    validateForm() {
      if (this.$refs.copyForm.validate()) {
        this.saveCopy(this.section)
      }
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (this.section) {
      this.getCopy(this.section)
    }
  }
}
</script>