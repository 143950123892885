<template>
  <div>
    <!-- HEADER SHORTCODES -->
    <v-card 
      v-if="selected.header !== 'none'"
      color="#2d2d2d"
      height="450"
      class="mb-1"
      tile 
      flat 
      dark
    >
      <v-card-title>
        <span class="overline grey--text">header</span>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-clipboard:copy="selected.header == 'custom' ? shortcodes.customHeader : shortcodes.header"
              v-clipboard:success="onCopy"
              v-on="on"
              icon
              absolute
              right
              top
              dark
            >
              <v-icon color="grey">mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Copy Shortcodes</span>
        </v-tooltip>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="code-content">
        <pre class="language-none" v-html="selected.header == 'custom' ? shortcodes.customHeader : shortcodes.header"></pre>
      </v-card-text>
    </v-card>

    <!-- BODY SHORTCODES -->
    <v-card 
      color="#2d2d2d"
      height="450"
      class="mb-1"
      tile
      flat
      dark
    >
      <v-card-title>
        <span class="overline grey--text">body</span>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-clipboard:copy="bodyCode"
              v-clipboard:success="onCopy"
              v-on="on"
              icon
              absolute
              right
              top
              dark
            >
              <v-icon color="grey">mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Copy Shortcodes</span>
        </v-tooltip>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="code-content">
        <pre class="language-none" v-html="bodyCode"></pre>
      </v-card-text>
    </v-card>
    
    <!-- FOOTER SHORTCODES -->
    <v-card
      v-if="selected.footer !== 'none'"
      color="#2d2d2d"
      height="450"
      class="mb-1"
      tile
      flat
      dar
    >
      <v-card-title>
        <span class="overline grey--text">footer</span>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-clipboard:copy="selected.footer == 'custom' ? shortcodes.customFooter : shortcodes.footer"
              v-clipboard:success="onCopy"
              v-on="on"
              icon
              absolute
              right
              top
              dark
            >
              <v-icon color="grey">mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Copy Shortcodes</span>
        </v-tooltip>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="code-content">
        <pre class="language-none" v-html="selected.footer == 'custom' ? shortcodes.customFooter : shortcodes.footer"></pre>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>

// import Prism from 'prismjs'
import { mapState } from 'vuex'
import _orderBy from 'lodash/orderBy'

export default {
  name: 'Shortcodes',

  computed: {
    ...mapState({
      shortcodes: state => state.sections.shortcodes,
      selected: state => state.page.selected,
    }),

    bodyCode: function () {
      let shortcodes = _orderBy(this.shortcodes.body, 'order', 'asc')
      let combinedShortcodes = ''

      shortcodes.forEach(shortcode => {
        combinedShortcodes = combinedShortcodes.concat(shortcode.data)
      })

      return combinedShortcodes
    }
  },

  watch: {
    'sections': function () {
      console.log(this.sections)
    }
  },

  methods: {
    onCopy: function (e) {
      if (e) {
        this.$store.dispatch('showSuccess', 'Copied to clipboard')
      }
    }
  }
}

</script>

<style lang="scss">

pre[class*="language-"] {
    overflow: visible;
    white-space: pre-wrap;
}

</style>
