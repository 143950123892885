<template>
  <div class="d-inline-block">
    <parent-breadcrumb v-if="page.parent" :page="parent" />

    <div v-if="page.parent" class="d-inline-block subtitle-2 dark--text">
      <span v-if="parent">{{ parent.name }}</span> <v-icon small color="grey lighten-1">mdi-menu-right-outline</v-icon>
    </div>
  </div>
</template>

<script>

import ParentBreadcrumb from '@/components/ParentBreadcrumb'

export default {
  name: 'ParentBreadcrumb',

  props: {
    page: Object
  },

  components: {
    ParentBreadcrumb
  },

  computed: {
    parent: function () {
      return this.$store.getters['page/pageParent'](this.page.parent)
    }
  }
}

</script>
