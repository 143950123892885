<template>
  <div>
    <!-- HEADER SHORTCODES -->
    <v-card
      v-if="selected.header !== 'none'"
      color="#2d2d2d"
      height="450"
      class="mb-1"
      tile 
      flat 
      dark
    >
      <v-card-title>
        <span class="overline grey--text">header</span>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-clipboard:copy="csscodes.header"
              v-clipboard:success="onCopy"
              v-on="on"
              icon
              absolute
              right
              top
              dark
            >
              <v-icon color="grey">mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Copy CSS Codes</span>
        </v-tooltip>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="code-content">
        <pre class="language-none" v-html="cssHeader"></pre>
      </v-card-text>
    </v-card>

    <!-- BODY SHORTCODES -->
    <v-card
      color="#2d2d2d"
      height="450"
      class="mb-1"
      tile 
      flat 
      dark
    >
      <v-card-title>
        <span class="overline grey--text">body</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-clipboard:copy="bodyCssRaw"
              v-clipboard:success="onCopy"
              v-on="on"
              icon
              absolute
              right
              top
              dark
            >
              <v-icon color="grey">mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Copy CSS Codes</span>
        </v-tooltip>

      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="code-content">
        <pre class="language-none" v-html="bodyCss"></pre>
      </v-card-text>
    </v-card>
    
    <!-- FOOTER SHORTCODES -->
    <v-card
      v-if="selected.footer !== 'none'"
      color="#2d2d2d"
      height="450"
      class="mb-1"
      tile 
      flat 
      dark
    >
      <v-card-title>
        <span class="overline grey--text">footer</span>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-clipboard:copy="csscodes.footer"
              v-clipboard:success="onCopy"
              v-on="on"
              icon
              absolute
              right
              top
              dark
            >
              <v-icon color="grey">mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Copy CSS Codes</span>
        </v-tooltip>

      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="code-content">
        <pre class="language-css" v-html="cssFooter"></pre>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>

import { mapState } from 'vuex'
import _orderBy from 'lodash/orderBy'
import _uniqBy from 'lodash/uniqBy'
import Prism from 'prismjs'

export default {
  name: 'Shortcodes',

  computed: {
    ...mapState({
      csscodes: state => state.sections.csscodes,
      selected: state => state.page.selected,
    }),

    cssHeader: function () {
      let headerCss = this.selected.header == 'custom' ? this.csscodes.customHeader : this.csscodes.header
      return Prism.highlight(headerCss || '', Prism.languages.css, 'css')
    },
    
    cssFooter: function () { 
      let footerCss = this.selected.footer == 'custom' ? this.csscodes.customFooter : this.csscodes.footer
      return Prism.highlight(footerCss || '', Prism.languages.css, 'css')
    },

    bodyCssRaw: function () {
      let csscodes = _orderBy(this.csscodes.body, 'order', 'asc')
      csscodes = _uniqBy(csscodes, 'identifier')
      let combinedCss = ''

      csscodes.forEach((css, i) => {
        combinedCss = combinedCss.concat(`${ i ? '\n\n' : '' }/*------------------------------------------------------------------------------\n * ${css.name} ${this.$options.filters.capitalize(css.color)}\n *----------------------------------------------------------------------------*/\n\n`, css.data || '')
      })

      return combinedCss
    },

    bodyCss: function () {
      let combinedCss = this.bodyCssRaw
      return Prism.highlight(combinedCss, Prism.languages.css, 'css')
    }
  },

  watch: {
    'csscodes': function () {
      console.log(this.csscodes)
    }
  },

  methods: {
    onCopy: function (e) {
      if (e) {
        this.$store.dispatch('showSuccess', 'Copied to clipboard')
      }
    }
  }
}

</script>

<style lang="scss">

pre[class*="language-"] {
    overflow: visible;
    white-space: pre-wrap;
}

</style>
