<template>
  <div class="fill-height">
    <!-- IF NO PERMISSION -->

    <preloader
      v-if="$store.getters['project/access'] == 'checking'"
      message="Checking permission"
    />

    <!-- IF NO ACCESS -->
    <template v-if="!$store.getters['project/access']">
      <v-row align="center" justify="center">
        <no-access :project="project" />
      </v-row>
    </template>

    <!-- CONTINUE IF PERMITTED -->
    <template v-else-if="$store.getters['project/access'] === true">
      <v-alert v-if="!selected" type="info">
        Create or select a page
      </v-alert>

      <div v-if="selected">
        <cta-banner v-if="project" :project="project" />

        <v-sheet :class="!$store.state.preview ? 'd-flex' : 'd-none'" color="transparent" class="justify-space-between align-center mb-5 pa-0 mx-auto" max-width="950">
          
          <div class="subtitle-2 dark--text">
            <v-icon small left>{{ selected.home ? 'mdi-home' : 'mdi-book-open-page-variant' }}</v-icon>

            <breadcrumb v-if="selected.parent" :page="selected" />

            {{ selected.name }}
          </div>

          <div class="d-flex gap-10 align-center">
            <v-tooltip v-if="($store.getters['user/isEmployee'] || $store.getters['user/isAdmin']) && !isPageTemplate" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="showSelectTemplateDialog()"
                  color="primary"
                  class="subtle"
                  data-title="Page Templates"
                  v-intro="'Click here to import a page template'"
                  v-intro-step="5"
                  v-on="on"
                  icon
                  small
                >
                  <v-icon>mdi-page-next-outline</v-icon>
                </v-btn>
              </template>
              <span class="caption">Page Templates</span>
            </v-tooltip>

            <v-switch
              v-else-if="isPageTemplate"
              v-model="selected.visible"
              @change="$store.dispatch('pageTemplates/updateTemplate', selected)"
              :true-value="true"
              :false-value="false"
              hide-details
              class="ma-0"
              inset
              dense
            ></v-switch>
            
            <v-tooltip v-if="!codePreview" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="$store.commit('previewMode', true)"
                  color="primary"
                  class="subtle"
                  data-title="Preview Page"
                  v-intro="'Click here to view a fullscreen preview of your design'"
                  v-intro-step="6"
                  v-on="on"
                  icon
                  small
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span class="caption">Preview</span>
            </v-tooltip>

            <template v-if="!isPageTemplate">
              <v-tooltip v-if="user && (user.role == 'admin' || user.organization) " bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="setCodePreview(true)"
                    :input-value="codePreview"
                    color="primary"
                    class="subtle"
                    v-on="on"
                    icon
                    small
                  >
                    <v-icon>mdi-code-braces</v-icon>
                  </v-btn>
                </template>
                <span class="caption">View Codes</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-intro="'Click here to download your design'"
                    :loading="downloading"
                    data-title="Download PDF"
                    @click="downloadPdf()"
                    v-intro-step="7"
                    color="primary"
                    class="subtle"
                    v-on="on"
                    small
                    icon
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span class="caption">Download PDF</span>
              </v-tooltip>
              
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="startIntro()"
                    color="primary"
                    class="subtle"
                    v-on="on"
                    data-title="Restart Demo"
                    v-intro="'Click here any time to restart this demonstration'"
                    v-intro-step="8"
                    icon
                    small
                  >
                    <v-icon>mdi-lifebuoy</v-icon>
                  </v-btn>
                </template>
                <span class="caption">Restart Demo</span>
              </v-tooltip>

              <v-tooltip v-if="false" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    class="subtle"
                    v-on="on"
                    icon
                    small
                  >
                    <v-icon>mdi-television-play</v-icon>
                  </v-btn>
                </template>
                <span class="caption">Video Tutorials</span>
              </v-tooltip>

              <v-menu
                v-if="user"
                transition="slide-x-transition" 
                nudge-bottom="12"
                offset-y
                bottom
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    class="subtle"
                    v-on="on"
                    small
                    icon
                  >
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>

                <v-list min-width="120">
                  <v-list-item v-if="false" dense class="subtle">
                    <v-list-item-title class="primary--text"><v-icon small left>mdi-lifebuoy</v-icon> Support</v-list-item-title>
                  </v-list-item>
                  <v-divider v-if="false"></v-divider>
                  <v-list-item 
                    v-if="$store.getters['user/isAdmin'] || $store.getters['user/data'].managePageTemplates"
                    @click="showSaveTemplateDialog()" 
                    class="subtle" 
                    dense
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon small>mdi-tray-arrow-up</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Save as page template
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item 
                    v-if="$store.getters['user/isAdmin'] || $store.getters['user/data'].managePageTemplates"
                    @click="showSaveProjectTemplateDialog()"
                    class="subtle" 
                    dense
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon small>mdi-table-arrow-up</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Save as project template
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider v-if="false"></v-divider>
                  <v-list-item @click="deletePage()" class="subtle" dense>
                    <v-list-item-icon class="mr-2">
                      <v-icon small class="red--text">mdi-delete-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> 
                      Delete page
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </div>

        </v-sheet>

        <!------------------------------------------------------- PAGE ------------------------------------------------------->

        <v-sheet v-show="!codePreview" id="page" class="pa-0 mx-auto custom-shadow" :max-width="$store.state.preview ? '1440' : '950'">
          <!-- HEADER -->

          <div 
            v-if="selected.header !== 'none' && !isPageTemplate"
            data-title="Header Section"
            v-intro="'Click this box to select and add a header'"
          >
            <preloader v-if="gettingSections" message="Getting header section..." />
            
            <v-hover v-slot="{ hover }">
              <v-card 
                v-if="(!header || !Object.keys(header).length) && !gettingSections && !$store.state.preview"
                class="section-card dashed transition" 
                @click="selectTemplate('header')"
                :class="{ 'box-shadow': hover }"
                style="margin-bottom: -2px;"
                outlined
                tile 
              >
                <v-sheet min-height="200" class="d-flex justify-center align-center">
                  <div class="text-center">
                    <h6 class="primary--text body-1 subtle-text d-inline-flex align-center">
                      <v-icon left>mdi-plus-circle-outline</v-icon> CHOOSE A HEADER
                    </h6>
                  </div>
                </v-sheet>
              </v-card>
            </v-hover>

            <PageSection
              v-if="header && Object.keys(header).length && !gettingSections"
              :section="header"
              @delete="deleteSection"
              @replace="replaceSection"
            />
          </div>

          <!-- BODY -->
          <div
            data-title="Body Sections"
            v-intro="'Click this box to select and add body sections'"
            v-intro-step="2"
          >
            <BodySections
              :page="selected"
              @select="selectTemplate"
              @delete="deleteSection"
              @replace="replaceSection"
            />
          </div>

          <!-- FOOTER -->
          <div 
            v-if="selected.footer !== 'none' && !isPageTemplate"
            data-title="Footer Section"
            v-intro="'Click this box to select and add a footer'"
            v-intro-step="3"
          >
            <preloader v-if="gettingSections" message="Getting footer section..." />

            <v-hover v-slot="{ hover }">

              <v-card
                v-if="(!footer || !Object.keys(footer).length) && !gettingSections && !$store.state.preview"
                class="section-card dashed transition" 
                :class="{ 'box-shadow' : hover }"
                @click="selectTemplate('footer')" 
                outlined
                tile 
              >
                <v-sheet min-height="200" class="d-flex justify-center align-center">
                  <div class="text-center">
                    <h6 class="primary--text body-1 subtle-text d-inline-flex align-center">
                      <v-icon left>mdi-plus-circle-outline</v-icon> CHOOSE A FOOTER
                    </h6>
                  </div>
                </v-sheet>
              </v-card>
            </v-hover>

            <PageSection
              v-if="footer && Object.keys(footer).length && !gettingSections"
              :section="footer"
              @delete="deleteSection"
              @replace="replaceSection"
            />
          </div>
        </v-sheet>

        <!-- CODE PREVIEW -->
        <v-fade-transition>
          <v-sheet v-if="codePreview" color="transparent" max-width="1000" class="mx-auto">
            <v-tabs
              color="primary"
              slider-color="primary"
              class="mb-0"
            >
              <v-tab class="caption">Shortcode</v-tab>
              <v-tab class="caption">CSS</v-tab>
              <v-btn
                @click="codePreview = false"
                class="mt-3"
                color="dark"
                depressed
                absolute
                right
                small
                dark
              >
                View Page
              </v-btn>

              <v-tab-item class="mt-5">
                <short-codes/>
              </v-tab-item>
              
              <v-tab-item class="mt-5">
                <css-codes/>
              </v-tab-item>
            </v-tabs>
          </v-sheet>
        </v-fade-transition>

        <!-- CLOSE PREVIEW -->
        <v-tooltip v-if="$store.state.preview" left>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="$store.commit('previewMode', false)"
              style="z-index: 100"
              v-on="on"
              fixed
              top
              right
              fab
            >
              <v-icon>mdi-eye-off</v-icon>
            </v-btn>
          </template>
          <span>Hide Preview</span>
        </v-tooltip>
      </div>

      <!-- SELECT TEMPLATE -->
      <select-template
        :type="type"
        :show="showSelectTemplate"
        :current="currentBlock"
        :currentCategory="currentCategory"
        @close="closeSelectTemplate()"
        @selected="blockSelected"
      />

      <!-- CONFIRM DELETE PAGE -->
      <confirm-delete
        :show="showDelete"
        :message="deleteMessage"
        :deleting="status.deleting"
        @cancel="cancelDelete()"
        @confirmed="deleteConfirmed()"
        textConfirmation="Page"
        captcha
      />

      <!-- CONFIRM DELETE SECTION -->
      <confirm-delete
        :show="showDeleteSection"
        :message="deleteSectionMessage"
        :deleting="sectionStatus.deleting"
        @cancel="cancelDeleteSection()"
        @confirmed="deleteSectionConfirmed()"
        textConfirmation="Section"
        captcha
      />

      <!-- SHOW CTA -->
      <cta-dialog
        :show="showCta"
        @close="showCta = false"
      />

      <!-- SAVE TEMPLATE DIALOG -->
      <template-dialog 
        ref="templateDialog"
        :page="selected" 
        :header="header"
        :footer="footer"
      />

      <!-- SELECT TEMPLATE DIALOG -->
      <SelectTemplateDialog ref="selectTemplate" />

      <!-- SECTION DRAWER -->
      <v-navigation-drawer
        :value="$store.state.sections.status.drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        :width="$drawer.large"
        @input="drawerEvent"
        floating
        temporary
        fixed
        right
      >
        <v-card flat tile>
          <close-btn @click="$store.dispatch('sections/closeDrawer')" class="mt-2" depressed />
          
          <v-card-text class="pa-7 pt-12">
            <section-notes v-show="$store.state.sections.status.drawer == 'notes'" />
            <section-attachments v-show="$store.state.sections.status.drawer == 'attachments'" />
            <section-copy v-show="$store.state.sections.status.drawer == 'copy'" />
          </v-card-text>
        </v-card>

        <template v-slot:append>
          <v-card flat tile>
            <v-card-text class="d-flex gap-7">
              <v-tooltip 
                v-for="(btn, i) in btns"
                :key="i"
                top
              >
                <template v-if="btn.role == 'all' || (user && (user.role == btn.role || $store.getters['user/isEmployee']))" v-slot:activator="{ on }">
                  <v-btn
                    :input-value="$store.state.sections.status.drawer == btn.view"
                    @click="$store.dispatch('sections/select', {view: btn.view})"
                    v-on="on"
                    text
                    icon
                  >
                    <v-icon small> {{ btn.icon }} </v-icon>
                  </v-btn>
                </template>
                <span class="caption"> {{ btn.tooltip }} </span>
              </v-tooltip>
            </v-card-text>
          </v-card>
        </template>
      </v-navigation-drawer>
    </template>

    <!-- DOWNLOAD DIALOG -->
    <v-dialog
      v-model="downloadDialog"
      :width="$dialog.small"
      no-click-animation
      height="100"
      persistent
    >
      <v-card>
        <v-card-text class="pt-5 text-center">
          <v-progress-circular color="accent" indeterminate />

          <h5 class="text-center font-weight-bold text-h5 mt-3">Generating PDF</h5>
          <div class="text-center">Please wait...</div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- INTRO PANEL DIALOG -->
    <v-dialog
      v-model="introDialog"
      :max-width="$dialog.content"
    >
      <v-card>
        <v-card-text class="pa-5">
          <p class="text-h6">Let me take you on a tour of Lightning Builder!</p>

          <div class="d-flex gap-10 align-center">
            <v-btn color="primary" small>Let’s do it!</v-btn>
            <v-btn text small>Skip, for now</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- PROJECT TEMPLATE DIALOG -->
    <SelectProjectTemplates 
      @getDetails="
        getProjectDetails()
        $store.dispatch('page/getPages', $route.params.id)
      "
      ref="ProjectTemplates"
    />

    <!-- PROJECT TEMPLATE DIALOG -->
    <CreateProjectTemplateDialog ref="projectTemplateDialog" />
  </div>
</template>

<script>

import html2PDF from 'jspdf-html2canvas'
import { mapState, mapActions } from 'vuex'

import CssCodes from '@/components/CssCodes'
import NoAccess from '@/components/NoAccess'
import CtaDialog from '@/components/CtaDialog'
import ShortCodes from '@/components/ShortCodes'
import CtaBanner from '@/views/project/CtaBanner'
import PageSection from '@/components/PageSection'
import SectionCopy from '@/components/SectionCopy'
import BodySections from '@/components/BodySections'
import SectionNotes from './components/SectionNotes'
import breadcrumb from '@/components/ParentBreadcrumb'
import SelectTemplate from '@/components/SelectTemplateDialog'
import SectionAttachments from '@/components/SectionAttachments'
import TemplateDialog from '@/views/PageTemplates/SaveTemplateDialog'
import SelectTemplateDialog from '@/views/PageTemplates/SelectTemplateDialog'
import CreateProjectTemplateDialog from '../ProjectTemplates/CreateTemplateDialog'
import SelectProjectTemplates from '@/views/ProjectTemplates/SelectProjectTemplates'

export default {
  name: 'Project',

  metaInfo() {
    return {
      title: this.title
    }
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      type: null,
      showCta: false,
      toReplace: null,
      globalHeader: {},
      globalFooter: {},
      showDelete: false,
      currentBlock: null,
      codePreview: false,
      introDialog: false,
      downloading: false,
      deleteMessage: null,
      gettingSections: true,
      downloadDialog: false,
      toDeleteSection: null,
      currentCategory: null,
      showDeleteSection: false,
      showSelectTemplate: false,
      deleteSectionMessage: null,
      btns: [
        { icon: 'mdi-book-open-outline', view: 'notes', tooltip: 'Notes', role: 'all' },
        { icon: 'mdi-paperclip', view: 'attachments', tooltip: 'Attachments', role: 'admin' },
        { icon: 'mdi-file-document-outline', view: 'copy', tooltip: 'Section Copy', role: 'admin' },
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    CssCodes,
    NoAccess,
    CtaBanner,
    CtaDialog,
    breadcrumb,
    ShortCodes,
    SectionCopy,
    PageSection,
    SectionNotes,
    BodySections,
    SelectTemplate,
    TemplateDialog,
    SectionAttachments,
    SelectTemplateDialog,
    SelectProjectTemplates,
    CreateProjectTemplateDialog,
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      status: state => state.page.status,
      selected: state => state.page.selected,
      header: state => state.sections.header,
      footer: state => state.sections.footer,
      project: state => state.project.project,
      sectionStatus: state => state.sections.status,
      isPageTemplate: state => state.page.isPageTemplate,
    }),

    title: function () {
      return this.project ? `${this.project.name} Project` : 'Project'
    },
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'project': {
      handler () {
        this.$store.commit('sections/setHeader', null)
        this.$store.commit('sections/setFooter', null)
      },
      immediate: true
    },
    
    'selected': {
      handler () {
        this.codePreview = null
        this.checkCustomHeaderFooter()
      },
      immediate: true
    },

    '$route.params.id': {
      handler () {
        if (!this.$route.query.type) {
          this.getProjectDetails()
        }
      },
      immediate: true
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('project', [
      'getProject',
    ]),
    
    ...mapActions('user', [
      'updateShowDemo',
    ]),

    ...mapActions('page', [
      'getPageTemplate',
      'downloadPage',
      'duplicate',
      'delete',
    ]),
    
    ...mapActions('sections', [
      'getHeaderSection',
      'getFooterSection',
      'getSections',
      'replace',
      'create',
    ]),

    deletePage() {
      this.showDelete = true
      this.deleteMessage = `Delete page "${this.selected.name}"?`
    },

    cancelDelete() {
      this.showDelete = false
      this.deleteMessage = null
    },

    deleteConfirmed() {
      Promise.all([this.delete({
        page: this.selected,
        project: this.project.id
      })])
      .then(() => {
        this.cancelDelete()
      })
    },

    selectTemplate(type = 'body') {
      this.type = type
      if (this.type) this.showSelectTemplate = true
    },

    closeSelectTemplate() {
      this.type = null
      this.currentBlock = null
      this.toReplace = null
      this.currentCategory = null
      this.showSelectTemplate = false
    },

    blockSelected(data) {
      if (!this.toReplace) this.create(data)
      else this.replace({
        toReplace: this.toReplace,
        section: data
      })

      this.closeSelectTemplate()
    },

    deleteSection(section) {
      this.showDeleteSection = true
      this.toDeleteSection = section
      this.deleteSectionMessage = 'Are you sure you want to delete this section? All details associated to this section will be deleted. This action cannot be undone.'
    },

    cancelDeleteSection() {
      this.showDeleteSection = false
      this.toDeleteSection = null
    },

    deleteSectionConfirmed() {
      Promise.all([this.$store.dispatch('sections/delete', this.toDeleteSection)])
      .then(() => {
        this.cancelDeleteSection()
      })
    },

    replaceSection(section) {
      this.selectTemplate(section.type)
      this.currentBlock = section.block
      this.currentCategory = section.category || null
      this.toReplace = section
    },

    setCodePreview(bol) {
      this.codePreview = bol
    },

    checkCustomHeaderFooter() {
      if (this.selected) {
        // CHECK CUSTOM HEADER
        if (!Object.keys(this.globalHeader).length) Object.assign(this.globalHeader, this.header)
        let header

        if (this.selected.header == 'custom') header = this.selected.customHeader
        else header = this.globalHeader

        this.$store.commit('sections/setHeader', header)

        // CHECK CUSTOM FOOTER
        if (!Object.keys(this.globalFooter).length) Object.assign(this.globalFooter, this.footer)
        let footer
        if (this.selected.footer == 'custom') footer = this.selected.customFooter
        else footer = this.globalFooter

        this.$store.commit('sections/setFooter', footer)
      }
    },

    drawerEvent(e) {
      if (!e) this.$store.dispatch('sections/closeDrawer')
    },

    downloadPdf() {
      if (!this.user) {
        this.$store.commit('user/setShowDialog', true)
        this.$store.commit('user/setActionType', 'signup')
      }
      else {
        this.downloading
        this.downloadDialog = true
        this.$store.commit('page/downloadingState', true)
        
        setTimeout(() => {
          Promise.resolve(this.downloadPagePdf())
          .then(() => {
            this.downloading = false
            this.downloadDialog = false
            this.$store.commit('page/downloadingState', false)
          })
        }, 1000)
      }
    },

    getProjectDetails() {
      this.$store.dispatch('collaborators/getCollaborators', this.$route.params.id)
      this.$store.commit('sections/resetState')

      // GET PROJECT DATA
      Promise.resolve(this.getProject(this.$route.params.id))
      .then(() => {
        this.$watch('selected', function (val) {
          if (val) this.getPageSections()
        }, { immediate: true })
      })

      this.$store.commit('page/setSelected', null)
      this.$store.dispatch('blocks/getCategories')
      this.checkCustomHeaderFooter()
    },

    getPageSections() {
      // GET PAGE SECTIONS
      Promise.all([
        this.getHeaderSection(),
        this.getFooterSection(),
        this.getSections(this.selected)
      ])
      .then(response => {
        this.globalHeader = response[0] || this.globalHeader
        this.globalFooter = response[1] || this.globalFooter
        this.gettingSections = false
        
        if (
          !response[0] && 
          !response[1] && 
          !response[2] && 
          !this.$store.state.sections.sections.length &&
          this.$store.state.page.pages.length == 1
        ) {

          if (!this.user || this.user.showDemo) {
            setTimeout(() => {
              this.startIntro()
            }, 1500)
          }
        }
      })
    },

    showSaveTemplateDialog() {
      this.$refs.templateDialog.dialog = true
    },

    showSelectTemplateDialog() {
      this.$refs.selectTemplate.dialog = true
    },

    startIntro() {
      this.$intro()
      .setOptions({
        disableInteraction: true,
        scrollToElement: false,
        exitOnOverlayClick: false,
      })
      .onbeforeexit(() => {
        this.updateShowDemo(false)
      })
      .start()
      
      this.$intro().showHints()
    },

    showSaveProjectTemplateDialog() {
      this.$store.commit('projects/updateStatus', { projectToTemplate: true })
      this.$refs.projectTemplateDialog.dialog = true
    },

    async downloadPagePdf() {
      let page = document.getElementById('page')
      let height = page.clientHeight
      let width = page.clientWidth

      await html2PDF(page, {
        jsPDF: {
          unit: 'px',
          format: [width, height],
        },
        imageQuality: 1,
        imageType: 'image/jpeg',
        output:  `${this.selected.name}.pdf`,
        html2canvas: {
          width,
          height,
          scrollX: 0,
          scrollY: -window.scrollY,
        },
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.$store.commit('project/resetProject')

    if (this.$route.query.type == 'page-template') {
      this.$store.commit('page/isPageTemplateState', true)
      this.getPageTemplate(this.$route.params.id)
      this.$store.dispatch('blocks/getCategories')
    }
    else if (this.$route.query.template) {
      this.$refs.ProjectTemplates.dialog = true
    }
    else {
      this.getProjectDetails()
      this.$store.commit('page/isPageTemplateState', false)
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$intro().exit()
    next()
  }
}

</script>

<style lang="scss">
.subtle-text {
  opacity: 0.4;
}

.section-card:hover {
  z-index: 20;
}

.theme--light.v-sheet--outlined.dashed {
  border-style: dashed !important;
  border-width: 2px;
}

.code-content {
  height: calc(100% - 74px);
  overflow-y: scroll;
}

.introjs-tooltip {
  background-color: var(--v-primary-base);
}

.introjs-arrow.bottom, 
.introjs-arrow.bottom-middle, 
.introjs-arrow.bottom-right {
  border-color: var(--v-primary-base) transparent transparent;
}

.introjs-arrow.top, 
.introjs-arrow.top-middle, 
.introjs-arrow.top-right {
  border-color: transparent transparent var(--v-primary-base);
}

.introjs-tooltip-title,
.introjs-skipbutton {
  color: white;
}

</style>
