<template>
  <div>
    <v-text-field
      v-model="search"
      placeholder="Search"
      append-icon="mdi-magnify"
      hide-details
      class="mb-3"
      clearable
      outlined
      dense
    ></v-text-field>

    <v-fade-transition group>
      <a 
        v-for="template in refined"
        :key="template.id"
        :class="{ 'grey lighten-2' : selected && template.id == selected.id }"
        @click="selectTemplate(template)"
        class="body-sm d-block pa-2"
      >
        {{ template.name }}
      </a>
    </v-fade-transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { orderBy } from 'lodash'

export default {
  props: {
    categories: Array,
    showVisiblesOnly: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      search: null,
      selected: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: (state) => state.allProjects.status,
      templates: (state) => state.allProjects.templates,
    }),

    refined: function () {
      let refined = this.templates

      if (this.search) {
        refined = refined.filter((template) => {
          return template.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }

      refined = refined.filter((template) => {
        return this.categories.includes(template.category)
      })

      refined = orderBy(refined, 'name', 'asc')

      return refined
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('allProjects', [
      'getProjectTemplates'
    ]),

    selectTemplate(template) {
      this.selected = template
      this.$emit('selected', template)
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.status.firstTemplateLoad) this.getProjectTemplates(true)
  }
}
</script>